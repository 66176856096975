import * as React from "react";
import { Global } from "@emotion/react";
import { GlobalStyles as BaseStyles } from "twin.macro";
import { Link } from "gatsby";
import SEO from "./sections/SEO";
import { customStyles } from "./page.styles";

const NotFoundPage = () => {
  return (
    <>
      <BaseStyles />
      <Global styles={customStyles} />
      <SEO />
      <main tw="mt-24 py-24 text-center">
        <h1 tw="pb-12 text-4xl">Page not found</h1>
        <Link to="/" tw="underline text-2xl">
          Go home
        </Link>
      </main>
    </>
  );
};

export default NotFoundPage;
